// Jekyll Simple Search
// -------------------------------------------------
.search-form {
  width: 100%;
  position: relative;
  opacity: 0;
  transition: all 200ms 100ms cubic-bezier(0, 0.6, 0.4, 1);
  top: 0;
  left: -200px;
  z-index: 9002;
  .search-field {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    color: $white;
    border: none;
    border-bottom: 1px solid lighten($white, 20);
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    background-clip: padding-box;
    @include font-size(32);
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &.active {
    opacity: 1;
    top: 0;
    left: 0;
  }
  &.hidden {
    display: none;
  }
  ::-webkit-input-placeholder {
    @include font-size(32);
  }
  .search-field::-webkit-search-decoration,
  .search-field::-webkit-search-cancel-button,
  .search-field::-webkit-search-results-button,
  .search-field::-webkit-search-results-decoration {
    display: none;
  }
  .post-list {
    position: absolute;
    width: 100%;
    h4, li, p, a {
      color: $white;
    }
    li {
      border-bottom: 1px solid lighten($white,20);
    }
  }
}
// search button in navigation
.no-js .dosearch {
  display: none;
}
.dosearch {
  span {
    display: block;
    margin-bottom: 10px;
    padding: 12px 20px;
    @include media($medium) {
      margin-bottom: 0;
      padding: 6px 10px;
    }
    cursor: pointer;
    @include rounded(4px);
    background-color: transparent;
    color: $white;
    &:hover {
    @include box-shadow($shadow: inset 0 0 1px $white);
      background-color: lighten($black, 10);
    }
  }
}