hr { 
    display: block; 
    margin: 1em 0; 
    padding: 0;
    height: 1px; 
    border: 0; 
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff; 
}

// Figures and images
// --------------------------------------------------
figure {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  @include clearfix;
  img {
    margin-bottom: 10px;
  }
  a { 
    img {
      @include transform(translateY(0));
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      -o-transition-duration: 0.25s;
      opacity: 0.7;
      &:hover {
        @include transform(translateY(-5px));
        @include box-shadow(0 0 10px rgba($black, .20));
        opacity: 1;
      }
    }
  }
  &.half {
    @include media($large) {
      img {
        width: 47.5%;
        float: left;
        margin-right: 2.5%;
        margin-bottom: 2.5%;
      }
      figcaption {
        clear: left;
      }
    }
  }
  &.third {
    @include media($large) {
      img {
        width: 30.8%;
        float: left;
        margin-right: 2.5%;
        margin-bottom: 2.5%;
      }
      figcaption {
        clear: left;
      }
    }
  }
}
svg:not(:root) {
  overflow: hidden; 
}


// Tables
// --------------------------------------------------
table { 
  width: 100%;
}

// Buttons
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 20px;
  @include font-size(14);
  background-color: $black;
  color: $white;
  border: 2px solid $black !important;
  border-radius: $border-radius;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $black;
  }
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: transparent;
  border: 0 solid transparent;
  color: $white;
}

// Well
// --------------------------------------------------
.well {
  padding: 20px;
  border: 1px solid $comp-color;
  @include rounded(4px);
}

// Animations
// --------------------------------------------------
.animated{-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;-ms-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:1s;-moz-animation-duration:1s;-ms-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;}.animated.hinge{-webkit-animation-duration:2s;-moz-animation-duration:2s;-ms-animation-duration:2s;-o-animation-duration:2s;animation-duration:2s;}@-webkit-keyframes fadeIn {
  0% {opacity: 0;}  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }
  
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  
  70% {
    -webkit-transform: scale(.9);
  }
  
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(.3);
  }
  
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  
  70% {
    -moz-transform: scale(.9);
  }
  
  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(.3);
  }
  
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  
  70% {
    -o-transform: scale(.9);
  }
  
  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  
  70% {
    transform: scale(.9);
  }
  
  100% {
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  
  80% {
    -webkit-transform: translateY(-10px);
  }
  
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  
  80% {
    -moz-transform: translateY(-10px);
  }
  
  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  
  80% {
    -o-transform: translateY(-10px);
  }
  
  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  
  80% {
    transform: translateY(-10px);
  }
  
  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes drop {
  0% {
    -webkit-transform: translateY(-500px);
  } 
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes drop {
  0% {
    -moz-transform: translateY(-500px);
  } 
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes drop {
  0% {
    -o-transform: translateY(-500px);
  } 
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes drop {
  0% {
    transform: translateY(-500px);
  } 
  100% {
    transform: translateY(0);
  }
}
.drop {
  -webkit-animation-name: drop;
  -moz-animation-name: drop;
  -o-animation-name: drop;
  animation-name: drop;
}